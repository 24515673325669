@mixin make-row($gutter: $grid-gutter-width) {
    display: flex;
    flex-wrap: wrap;
}

@mixin make-col-ready() {
    box-sizing: if(variable-exists(include-column-box-sizing) and $include-column-box-sizing, border-box, null);
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
}

@mixin make-col($size: false, $columns: $grid-columns) {
    @if $size {
        flex: 0 0 auto;
        width: percentage(divide($size, $columns));

    } @else {
        flex: 1 1 0;
        max-width: 100%;
    }
}

@mixin make-col-auto() {
    flex: 0 0 auto;
    width: auto;
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @include media-breakpoint-up($breakpoint, $breakpoints) {
            .col#{$infix} {
                flex: 1 0 0%;
            }

            .col#{$infix}-auto {
                @include make-col-auto();
            }

            @if $columns > 0 {
                @for $i from 1 through $columns {
                    .col#{$infix}-#{$i} {
                        @include make-col($i, $columns);
                    }
                }
            }
        }
    }
}
