.topBar {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    background-color: $third;
    padding: 8px 0;
    font-size: smaller;
    color: rgb(255 255 255 / 90%);


    .contact-info {
        font-weight: 100;
        display: flex;
        align-items: center;

        a {
            padding-left: 0.3rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            white-space: nowrap;

            @include breakpointDown($lg) {
                padding-left: 0.1rem;
                padding-right: 1rem;
            }

            &:last-child {
                padding-left: 3rem;

                @include breakpointDown($lg) {
                    padding-left: 0.1rem;
                }
            }

            svg {
                height: 1rem;
                width: auto;
            }
        }
    }

    .social-icons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;

        @include breakpointDown(365px) {
            display: none;
        }

        a {
            svg {
                height: 1.2rem;
                width: auto;
            }
        }
    }

}


header {
    background: $third;
    color: $white;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 99999;

    nav {
        display: flex;

        @include breakpointDown($lg) {
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .navbar-brand {
            padding: 1.2rem;
            margin-right: 1rem;
            padding-left: 0;

            @include breakpointDown($lg) {
                padding: 0.8rem 0;
            }

            img {
                max-width: 11rem;
            }
        }

        .navbar-collapse {
            display: flex;
            flex-grow: 1;
            align-items: center;

            @include breakpointUp($lg+1) {
                display: flex !important;
                flex-basis: auto;
            }

            @include breakpointDown($lg) {
                flex-direction: column;
                width: 100%;
            }

            ul {
                margin-right: auto;
                display: flex;
                align-items: center;
                height: 100%;

                @include breakpointDown($lg) {
                    flex-direction: column;
                    width: 100%;
                }

                .nav-item {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    &:hover {
                        background: #2F2F41;

                        @include breakpointDown($lg) {
                            background: none;
                        }
                    }

                    &-active {
                        &:hover {
                            background: none;
                        }
                    }

                    .nav-link {
                        margin-left: 14px;
                        margin-right: 14px;
                        color: white;
                        font-size: medium;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        @include breakpointDown($lg) {
                            padding: 1rem 0;
                            font-size: 1.1em;
                            font-weight: bold;
                            margin-bottom: 0.7rem;
                        }
                    }

                    .nav-link-active {
                        color: $primary;

                        svg {
                            fill: $primary !important;
                        }
                    }

                    .dropdown-toggle {
                        cursor: pointer;

                        svg {
                            display: none;
                            @include breakpointDown($lg) {
                                display: block;
                                fill: #eee;
                                width: 0.7rem;
                                margin-left: 0.5rem;
                            }
                        }
                    }

                    .dropdown-menu {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        z-index: 1000;
                        display: none;
                        width: max-content;
                        max-width: 227.5px;
                        padding: 0.5rem 0;
                        flex-direction: column;
                        font-size: 1rem;
                        color: #fff;
                        background-color: #2F2F41;

                        @include breakpointDown($lg) {
                            position: initial;
                            max-width: none;
                            width: 100%;
                            text-align: center;
                            padding-top: 0;
                            background: none;
                        }


                        .dropdown-item {
                            padding: 0.8rem 1.5rem;

                            @include breakpointDown($lg) {
                                padding: 0.7rem 0;
                            }

                            &:hover {
                                background: #FFFFFF26;

                                @include breakpointDown($lg) {
                                    background: none;
                                }
                            }
                        }
                    }

                    .dropdown-menu.show {
                        display: flex;
                    }
                }

                .dropdown {
                    position: relative;

                    @media only screen and (min-width: $lg + 1) {
                        &:hover .dropdown-menu {
                            display: flex;
                        }
                    }


                    @include breakpointDown($lg) {
                        display: flex;
                        flex-direction: column;
                    }
                }


            }



            > a {
                @include breakpointDown($lg) {
                    margin: 1rem 0 2rem;
                }
            }
        }


        .collapse:not(.show) {
            display: none;
        }


        .navbar-menu-icon {
            padding: rem(20);
            margin-right: rem(-20);
            z-index: 10;
            display: block;

            @media only screen and (min-width: $lg + 1) {
                display: none;
            }

            .icon-line-item {
                margin: rem(4) 0;
                width: rem(20);
                height: rem(2);
                border-radius: 2px;
                background: white;
                transition: transform $animationFast;
                display: block;
            }

            .icon-line-01 {
                transform-origin: left;
            }

            .icon-line-03 {
                transform-origin: right;
            }
        }

    }
}


header {
    nav {
        .navbar-menu-icon:not(.collapsed) {
            .icon-line-01 {
                transform: translate(3px, -1px) rotate(45deg) scaleX(0.5);
            }

            .icon-line-02 {
                transform: rotate(-45deg);
            }

            .icon-line-03 {
                transform: translate(-3px, 1px) rotate(45deg) scaleX(0.5);
            }
        }
    }
}

