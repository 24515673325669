// Text

.text {
    &-primary {
        color: $primary;
    }

    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-end {
        text-align: right;
    }

    &-lg-end {
        @include breakpointUp($lg) {
            text-align: right;
        }
    }

    &-justify {
        text-align: justify;
    }
}


// Grid
.image-ratio {
    max-width: 100%;
    height: 100%;
}


.pb-0 {
    padding-bottom: 0;
}


.mb-0 {
    margin-bottom: 0;
}


.relative {
    position: relative;
}


.flex {
    display: flex;

    &-col {
        flex-direction: column;
    }

    &-md-row {
        @include breakpointUp($md) {
            flex-direction: row;
        }
    }

    &-row-reverse {
        flex-direction: row-reverse;
    }

    &-wrap {
        flex-wrap: wrap;
    }
}


.justify {
    &-center {
        justify-content: center;
    }

    &-between {
        justify-content: space-between;
    }

    &-evenly {
        justify-content: space-evenly;
    }

    &-start {
        justify-content: flex-start;
    }

    &-end {
        justify-content: flex-end;
    }
}


.items {
    &-center {
        align-items: center;
    }

    &-start {
        align-items: flex-start;
    }

    &-end {
        align-items: flex-end;
    }

}

.align-self-center {
    align-self: center;
}


.order {
    &-0 {
        order: 0;
    }

    &-1 {
        order: 1;
    }

    &-lg-0 {
        @include breakpointUp($lg) {
            order: 0;
        }
    }

    &-lg-1 {
        @include breakpointUp($lg) {
            order: 1;
        }
    }
}



// Display
.d-none {
    display: none;
}


.d-block {
    display: block;
}


.d-md-block {
    @include breakpointUp($md) {
        display: block;
    }
}


.mt-3 {
    margin-top: 0.5rem;
}

.mb-5 {
    margin-bottom: 1.5rem;
}


