section {
    margin: 7rem 0;

    @include breakpointDown($lg) {
        margin: 5rem 0;
    }

    @include breakpointDown($md) {
        margin: 4rem 0;
    }

    @include breakpointDown($sm) {
        margin: 3rem 0;
    }
}


.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: 1px solid $third;
    background: #fff;
    transform: rotate(270deg);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: 9999;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
    transition: .2s all;
    cursor: pointer;

    svg {
        width: 16px;
        height: 16px;
        fill: $third;
    }

    &:hover {
        background-color: #f7f7ff;
    }

    &.fade-in {
        animation: fade-in 0.3s forwards;
    }

    &.fade-out {
        animation: fade-out 0.3s forwards;
    }
}


@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}


h1, h2 {
    //background-color: black;
    color: black;
}


h1 {
    margin-bottom: 0.5rem;
    width: 100%;
}

h2 {
    width: fit-content;
}

.main-slider {
    margin-top: 3rem;

    @include breakpointDown($lg) {
        margin-top: 1rem;
    }

    @include breakpointDown($sm) {
        margin-top: 0;
    }
}


.slider-content {
    //padding: 0 70px;
    //width: 100%;

    @include breakpointDown($md) {
        padding: 6rem 70px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @include breakpointDown($sm) {
        padding: 5rem 30px;
    }

    p {
        margin: 1.2rem 0 0;
        margin-bottom: 3rem;
    }

    //.button-primary {
    //    margin-top: 2rem;
    //}
}


.swiper-pagination {
    position: absolute;
    left: 1.25rem !important;
    text-align: left !important;
    bottom: 2rem !important;

    @include breakpointDown($md) {
        bottom: 18rem !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        text-align: center !important;
    }
}


.swiper-pagination-bullet {
    width: 13px !important;
    height: 13px !important;
    background-color: #fff !important;
    border: 1px solid;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}


.swiper-pagination-bullet-active {
    background: $primary !important;
}


.swiper-slide-hidden {
    opacity: 0;
}


.slider-image {
    background-size: cover !important;
    width: 100% !important;
    height: 100% !important;
    background-position: center;

    img {
        height: 30rem;
        object-fit: cover;
        object-position: center center;

        @include breakpointDown($sm) {
            height: 25rem;
        }
    }
}

.swiper-slide-visible {
    opacity: 0 !important;
}

.swiper-slide-active {
    opacity: 1 !important;
}


.swiper-container {
    height: 100%;
    overflow: hidden;
    position: relative;

    &:before {
        @include breakpointDown($lg) {
            width: 56% !important;
        }
    }
}


.swiper-slide {
    display: flex !important;
    align-items: center !important;
    padding: 0 1.25rem;
    height: initial !important;

    @include breakpointDown($md) {
        flex-direction: column;
        text-align: center;
        padding: 0;
    }


    .coponukameleft {
        //padding-bottom: 10rem;
        background: #F5F5F5;
        width: 640px;

        @include breakpointDown($md) {
            height: 450px;

        }
        @include breakpointDown($xxl) {
            min-width: auto;
        }

        //@include breakpointDown($lg) {
        //    padding-bottom: 7rem;
        //}
        //
        //@include breakpointDown($md) {
        //    padding-bottom: 5rem;
        //}
    }

    @include breakpointDown($xxl) {
        max-width: 100%;

        .coponukameleft {
            width: 50%;
        }

        .coponukameright {
            width: 50%;

            img {
                width: 65% !important;
            }
        }
    }

    @include breakpointDown($lg) {
        .coponukameleft {
            width: 70%;
        }
    }

    @include breakpointDown($md) {
        .coponukameleft {
            width: 100%;
        }

        .coponukameright {
            width: 100%;

            img {
                width: 100% !important;
            }
        }
    }


}


.swiper-wrapper {
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box !important;
}


.coponukame {
    padding: 3rem;
    width: 100%;
    margin: 0;
}


.referencie {
    text-align: right;
}


.coponukameleft {
    padding: 74px;
    //background: #F5F5F5;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    align-items: flex-start;

    @include breakpointDown($xl) {
        padding: 3rem;
        padding-bottom: 4rem;
        padding-left: 0;
    }

    @include breakpointDown($md) {
        text-align: center;
        background: #F5F5F5;
        align-items: center;
        padding-left: 3rem;
    }

    @include breakpointDown($sm) {
        padding: 3rem 2rem
    }

    a {
        display: inline-block;
    }

    .buttons-group {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 0.5rem;

        @include breakpointDown($sm) {
            justify-content: center;
            margin-bottom: 4rem
        }
    }

    p {
        margin-top: 1rem;
    }
}


.coponukameright {
    padding: 74px;
    padding-right: 0;
    //background: #0A0A20;
    color: $grey;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    gap: 0.5rem;

    @include breakpointDown($xl) {
        padding: 3rem;
        justify-content: center;
        padding-right: 0;
    }

    @include breakpointDown($md) {
        background: #0A0A20;
        padding-right: 3rem;
    }

    @include breakpointDown($sm) {
        padding: 3rem 2rem;
        text-align: center;
        flex-direction: column;
    }

    p {
        line-height: 1.5;
        letter-spacing: 0;
    }
}


.colored-section {
    position: relative;
    margin: 0 1.5vw;

    @include breakpointDown($md) {
        margin: 0;
    }

    &:before {
        content: '';
        position: absolute;
        width: 50%;
        left: 0;
        top: 0;
        height: 100%;
        background: #F5F5F5;
        z-index: -1;

        @include breakpointDown($md) {
            display: none;
        }
    }

    &:after {
        content: '';
        position: absolute;
        width: 50%;
        left: 50%;
        top: 0;
        height: 100%;
        background: #0A0A20;
        z-index: -1;

        @include breakpointDown($md) {
            display: none;
        }
    }

    .container-lg {
        @include breakpointDown($md) {
            padding: 0;
        }
    }
}


.colored-section-reverse {
    margin: 0;

    .container-lg {
        @include breakpointDown($lg) {
            padding: 0;
            min-width: 100%;
        }
    }

    .coponukameleft {
        @include breakpointDown($lg) {
            background: #0A0A20;
            padding: 3rem 1.25rem;
            text-align: left;

            .footer-col {
                width: 100%;
            }
        }
    }

    .footer-col-right {
        @include breakpointDown($lg) {
            padding: 3rem 1.25rem;
        }
    }

    &:before {
        background: #0A0A20;

        @include breakpointDown($lg) {
            display: none;
        }
    }

    &:after {
        background: #F5F5F5;

        @include breakpointDown($lg) {
            display: none;
        }
    }
}


.colored-section-top {
    overflow-x: hidden;
    margin-top: 1.5vw;

    &:after {
        background: none;
    }

    @include breakpointDown($md) {
        margin-top: 0;
    }

    .coponukameleft {
        justify-content: center;
    }

    .coponukameright {
        padding: 0;
        height: 22rem;

        @include breakpointDown($lg) {
            height: 17rem;
        }

        img,
        iframe {
            position: absolute;
            height: 100%;
            object-position: center;
            width: 50%;
            top: 0;

            @include breakpointDown($md) {
                width: 100%;
                position: initial;
            }
        }
    }
}


.padding-section {
    padding: 0 3rem;

    @include breakpointDown($lg) {
        padding: 0 1rem;
    }

    @include breakpointDown($sm) {
        padding: 0;
    }
}


button {
    color: black;
}


.headlines-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;

    @include breakpointDown($xl) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 2rem;
    }

    @include breakpointDown($sm) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        gap: 1rem;
    }
}


.headlines {
    font-weight: 500;
    display: block;
    color: #1a1a1a;
    flex: 1;

    @include breakpointDown($lg) {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: 1rem 0;
    }

    @include breakpointDown($md) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 80%;
        margin: 1rem auto;
    }

    svg {
        height: 1.6rem;
        width: auto;
        margin-bottom: 1rem;

        @include breakpointDown($lg) {
            margin-bottom: 0;
            height: auto;
            flex: none;
        }
    }
}


.footer-container {
    max-width: unset !important;
}


@media (max-width: 992px) {

    .paddingswiper {
        padding: 2rem;
        padding-top: 0px;
    }

    .coponukame {
        padding: 2rem;
    }
}


/* Add a media query for mobile devices (adjust the max-width as needed) */
@media (max-width: 768px) {
    .headlines {
        text-align: center;
    }

    .paddingswiper {
        padding: 0rem;
        padding-top: 0px;
    }

    .coponukame {
        padding: 0rem;
    }

    .referencie {
        text-align: unset;
    }

}


/* Base styling */
.img-overlay-container {
    position: relative;
}


.img-fluid-custom {
    z-index: 1;
    max-width: 43%;

    @include breakpointDown($xl) {
        max-width: 48%;
    }

    @include breakpointDown($lg) {
        max-width: 70%;
        max-height: 32rem;
        object-fit: cover;
    }

    @include breakpointDown($sm) {
        max-height: 32rem;
        max-width: 100%;
        height: 60rem;
    }
}


.text-overlay {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translateY(-50%);
    padding: 5rem 6rem;
    background-color: #F5F5F5;
    border: 10px solid #fff;
    border-radius: 0;

    @include breakpointDown($xxl) {
        padding: 3rem 4.5rem;
    }

    @include breakpointDown($md) {
        left: 1rem;
        right: 1rem;
        padding: 1rem;
    }

    h4 {
        position: relative;

        @include breakpointDown($md) {
            text-align: left;
        }

        &:before {
            content: '';
            width: 3px;
            height: 50%;
            position: absolute;
            top: 54%;
            left: -1.7rem;
            transform: translateY(-50%);
            background: $secondary;

            @include breakpointDown($md) {
                left: -0.5rem;
            }
        }
    }

    p {
        margin-bottom: 1rem;
        line-height: 1.5;
    }
}


.card-text-custom {
    padding-left: 17px;
    font-size: 15px;
}


/* Mobile responsiveness */
@media (max-width: 768px) {

    .card-text-custom {
        padding-left: 17px;
        font-size: 13px;
    }
}


:root {
    --no-of-slides: 5;
    --slides-in-view: 5;
    --slide-width: 230px;
    --slide-height: 110px;
    --iteration-time: 15s;
}


@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(var(--slide-width) * var(--no-of-slides) * -1));
    }
}


.partnersslider .carousel {
    max-width: 850px;
    margin: 0 auto;
}


.partnersslider .carousel__wrapper {
    display: flex;
    align-items: center;
    width: calc(var(--slides-in-view) * var(--slide-width));
    overflow: hidden;
    margin: 0 auto;
}


.partnersslider .carousel__slide {
    animation: scroll var(--iteration-time) linear infinite;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: var(--slide-width);
    height: var(--slide-height);
    box-sizing: border-box;
}


.partnersslider .carousel__image {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    height: 100%;
    margin: 36px 50px;
    background-position-y: center !important;

    @include breakpointDown($md) {
        margin: 16px 30px;
    }
}


.navbar {
    position: sticky;
    top: 0;
    z-index: 500;
}


.button-offer {
    background: #FEB02B;
    border: 0;
    padding: 8px 20px;
    margin-right: 9px;
    font-weight: 700;
}


.button-call {
    border: 2px solid #0A0A20;
    padding: 8px 20px;
    margin-right: 9px;
    font-weight: 700;
    border-radius: 2px;
}


@media (max-width: 768px) {
    .button-offer {
        margin-top: 5px;
    }

    .top-container {

        display: none;
    }

    .button-call {
        margin-top: 5px;
    }

    .footer-row {
        flex-direction: column-reverse;
        /* Reverses the order on mobile */
    }


    .location {
        justify-content: center;
        /* Centered in mobile */
        text-align: center;
    }

}


.collapsebutton {
    background: #F5F5F5;
    border: 0;
    padding: 18px 22px;
    text-align: left;
    font-weight: 600;
    width: 100%;
    max-width: 85%;
    margin: 10px;
    margin-left: 0;
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        width: 1rem;

        path {
            fill: $secondary;

        }
    }
}


.text-box {
    p {
        margin-bottom: 2rem;
        letter-spacing: 0;
        line-height: 1.5;

        &:last-child {
            margin-bottom: 0;
        }
    }

    img {
        max-width: 95%;
        margin-right: auto;
        height: 100%;
        object-fit: cover;

        @include breakpointDown($md) {
            max-width: 100%;
            margin-bottom: 2.5rem;
            height: auto;
        }
    }

    .rever {
        img {
            margin-left: auto;
        }
    }

    h6 {
        margin-bottom: 1rem;
    }

    ul {
        li {
            font-weight: 100;
            position: relative;
            margin-left: 0.5rem;
            margin-bottom: 0.9rem;
            line-height: 1.5;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 100%;
                background-color: $primary;
                width: 1.5px;
                left: -0.5rem;
            }
        }
    }
}


.vyrobcoviaSection {
    h4 {
        margin-bottom: 0;
    }

    img {
        max-width: 170px;
        width: 90%;
        margin: 0 1rem;

        @include breakpointDown($md) {
            max-width: 210px;
            margin: 1rem auto;
        }
    }
}


.activedcollapse button {
    background: #0A0A1F;
    color: white;

}


.activedcollapse button i {
    color: #EE7217;
}


.collapseinside {
    display: none;
}


.collapseright {
    background-color: $grey;
    padding: 0;
    align-self: center;

    .collapseright-item-box {
        padding: 3rem;

        @include breakpointDown($xl) {
            padding: 1.5rem;
        }

        p {
            font-size: 0.9em;
            line-height: 1.5;
            margin-bottom: 2rem;
        }

        a {
            color: $secondary;
            font-weight: bold;
            font-size: 0.9em;
        }
    }
}


.collapseright-item {
    @include breakpointDown($md) {
        background-color: $grey;
        padding: 2rem;

        p {
            font-size: 0.9em;
            line-height: 1.5;
            margin-bottom: 2rem;
        }

        a {
            color: $secondary;
            font-weight: bold;
            font-size: 0.9em;
        }
    }

    @include breakpointDown($sm) {
        padding: 1.5rem;
    }
}


.activedcollapse .collapseinside {
    @include breakpointDown($md) {
        display: flex;
        align-items: stretch;

        img {
            object-fit: cover;
            width: 45%;
        }
    }

    @include breakpointDown($sm) {
        display: flex;
        flex-direction: column;

        img {
            width: 100%;
        }

    }
}


.collapseright span {
    font-size: 15px;
    color: #EE7217;
    font-weight: 500;
}


collapse span {
    font-size: 15px;
    color: #EE7217;
    font-weight: 500;
}


.collapsemain {
    padding: 40px;
}


.bggrey {
    background-color: #F5F5F5;
}


.font14 {
    font-size: 14px;
}


@media (max-width: 768px) {
    .collapseright {
        display: none;
    }

    .collapsemain {
        padding: 0px;
    }

    .collapsebutton {
        max-width: 100%;
    }

    .collapsemain h4 {
        text-align: center;
    }

    .activedcollapse button {
        margin-bottom: 0px;
    }

}


.vsetkyrefencie {
    font-size: 0.9rem;
    color: $secondary;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;

    @include breakpointDown($sm) {
        justify-content: center;
        margin-top: -0.6rem;
        margin-bottom: 1.5rem;
    }

    svg {
        width: 1rem;
        margin-left: 0.5rem;


        path {
            fill: $secondary;
        }
    }
}


.referencia {
    background: $grey;

    .referencia-text {
        padding: 1.5rem 2rem;

        p {
            margin-bottom: 0.4rem;
            font-size: 0.9em;
        }
    }
}


.referencia-images {
    //background: $grey;
    padding: 1.2rem 2rem;
    margin-top: 0.4rem;
    margin-bottom: 1.2rem;
    display: none;

    &-0 {
        display: block;
    }

    p {
        font-weight: 500;
        font-size: 0.9em;
    }
}


.referenciegrid p {
    font-size: 14px;
}


.button-slider {
    overflow-x: auto;
    display: flex;
    white-space: nowrap;
    gap: 0.6rem;
    padding: 0.2rem 0;
    margin: -1rem 0;
}


.slideheigth {
    max-height: 350px;
}


.imgshowroom {
    max-width: 230px;
    margin: 10px;
    margin-right: 20px;
}


.vyrobcoviaright {
    text-align: right;
}


@media (max-width: 768px) {
    .vyrobcoviaright {
        text-align: center;
    }

    .contactgrid {
        padding: 20px;
    }
}


@media (max-width: 768px) {
    .rever {
        flex-direction: column-reverse;
        /* Reverses the order on mobile */
    }

}


.card {
    background-color: $grey;
    padding: 0.1rem 1.5rem 1.5rem;
    position: relative;
    height: 100%;
    z-index: 1;
    margin-bottom: 1.5rem;

    @include breakpointDown($lg) {
        height: calc(100% - 1.5rem);
    }

    @include breakpointDown($sm) {
        padding: 0.1rem 2.5rem 2rem;
    }

    h6 {
        font-size: 5em;
        margin-bottom: 1rem;
        font-weight: bold;
    }

    p {
        font-weight: bold;
        line-height: 1.5;
    }

    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -0.6rem;
        font-size: 19em;
        line-height: 1;
        color: #EAEAEA;
        z-index: -1;

        @include breakpointDown($lg) {
            font-size: 15em;
        }

        @include breakpointDown($sm) {
            font-size: 16em;
        }
    }
}


.coponukameleft h4 {
    font-weight: 700;
    margin-bottom: 2rem;

    @include breakpointDown($md) {
        margin-bottom: 1rem;
    }
}


.postupdivbody {
    background-color: #F5F5F5;
    background-size: contain;
    background-position: center right;
    background-position: calc(100% - 0px) center;
    background-repeat: no-repeat;
}


.postupdivbody h5 {
    font-size: 61px;
    font-weight: 700;
}


orange {
    color: #EE7217;
}


.pl10 {
    padding-left: 10px;
}


.contact-section {
    h6 {
        color: $secondary;
        margin-bottom: 0.5rem;
        margin-top: 1.5rem;
    }

    p {
        font-weight: 400;
        font-family: 'Titillium Web', sans-serif;
    }

    .footer-social-icon {
        display: flex;
        align-items: center;
        gap: 0.1rem;
        margin-top: 4rem;
        font-weight: 600;

        svg {
            width: 1.1rem;
        }
    }
}


form {
    background: $grey;
    padding: 3rem;

    @include breakpointDown($lg) {
        padding: 2rem;
    }

    @include breakpointDown($md) {
        margin-top: 1rem;
    }

    @include breakpointDown($sm) {
        padding: 1.5rem;
    }
}


.form-group {
    label {
        font-weight: lighter;
        display: block !important;
    }

    input,
    textarea,
    select {
        width: 100%;
        background: #fff;
        padding: 0.9rem 1rem;
        margin: 0.5rem 0 1.5rem;
    }
}


.text-red-validation {
    color: red;
    font-weight: lighter;
    margin-top: -1rem;
    display: block;
    margin-bottom: 1rem;
    font-size: 0.9em;
}


.form-group-gdpr {
    display: flex;
    margin-bottom: 2rem;

    input {
        margin-right: 0.5rem;
        width: 1rem
    }

    input:checked {
        background-color: $primary;
    }

    label {
        font-weight: 400;
    }
}


.showroomSection {
    img {
        @include breakpointDown($lg) {
            margin-top: 1rem;
        }
    }
}


//Cenová ponuka

.cenovaponuka-section {
    margin-top: 3rem;

    h1 {
        @include breakpointDown($lg) {
            margin: 0 auto 1.5rem;
        }
    }
    .row {
        margin: 20px !important;
    }
    @include breakpointDown($xl) {

    }

    svg {
        display: none;
        width: 1.1rem;
    }

    p {
        font-size: 1.1em;
    }

    h5 {
        margin-top: 1.5rem;
    }

    .steps-bar {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }


    .step {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
        font-family: "Titillium Web", sans-serif;

        @include breakpointDown($sm) {
            flex-direction: column;
            gap: 0.2rem;

            .step-title {
                font-size: 0.9em;
            }
        }
    }


    .step-number {
        background-color: white;
        color: #00000066;
        border-radius: 50%;
        border: 1px solid #00000066;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.1;
    }

    .step-active .step-number {
        background-color: #f0ad4e;
        border: 1px solid #f0ad4e;
        color: black;
    }


    .step.current .step-number {
        border: 1px solid #f0ad4e;
        color: black;
    }


    @media (max-width: 768px) {
        .steps-bar {
            margin-top: 15px;
            margin-bottom: 15px;
        }

    }


    .pad5 {
        padding: 5px;
    }


    .kalkcard .title {
        padding: 21px;
        padding-bottom: 0px;
    }


    .kalkcard {
        padding: 21px;
        background-color: #fff;
        font-size: 15px;
        min-height: 214px;
        position: relative;
        border: 1px solid #fff;
        cursor: pointer;
    }

    .kalcard-active {
        border: 1px solid $primary;
    }


    .kalkcard:hover {
        border: 1px solid $primary;
    }


    .kalkcardmulti {
        padding: 21px;
        background-color: #fff;
        font-size: 15px;
        min-height: 214px;
        position: relative;
    }


    .kalcardMultiItem {
        margin: 12px -21px 0 -21px;
        padding: 5px 21px;
        cursor: pointer;
        border: 1px solid #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .kalcardMultiItem-active {
        border: 1px solid $primary;
    }


    .kalcardMultiItem:hover {
        border: 1px solid $primary;
    }


    /* Base style for labels associated with checkboxes */
    .checkbox-label {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 25px;
        height: 25px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid #ccc;
        cursor: pointer;
        text-align: center;
        transition: background-color 0.2s, border-color 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .checkbox-labelinline {
        width: 23px;
        height: 23px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid #ccc;
        cursor: pointer;
        text-align: center;
        transition: background-color 0.2s, border-color 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .checkbox-input {
        opacity: 0;
        float: right;
    }


    /* Style for when the associated checkbox is not checked */
    .checkbox-input:not(:checked) + .checkbox-label > .fa-check {
        display: none;
    }


    /* Style for when the associated checkbox is checked */
    .checkbox-input:checked + .checkbox-label > .fa-check {
        display: inline;
        color: #000000;
        /* Set your desired color */
    }


    /* Style for the label when the associated checkbox is checked */
    .checkbox-input:checked + .checkbox-label {
        border-color: $secondary;
    }


    /* Style for when the associated checkbox is not checked */
    .checkbox-input:not(:checked) + .checkbox-labelinline > .fa-check {
        display: none;
    }


    /* Style for when the associated checkbox is checked */
    .checkbox-input:checked + .checkbox-labelinline > .fa-check {
        display: inline;
        color: #000000;
        /* Set your desired color */
    }


    /* Style for the label when the associated checkbox is checked */
    .checkbox-input:checked + .checkbox-labelinline {
        border-color: $secondary;
    }


    /* Optional: Add a class to style the check icon */
    .fa-check {
        font-size: 16px;
        /* Adjust the size as needed */
    }


    .dalejbutton {
        float: right;
    }


    .spatbutton {
        float: left;
    }

    .cenova-buttons {

        @include breakpointDown($sm) {
            display: flex;
            gap: 0.5rem;
            //justify-content: space-between;
        }
    }

    .upload-header-text {
        font-size: 1em;
        font-weight: 400;

        @include breakpointDown($md) {
            margin-top: 1rem;
        }
    }


    .custom-file-upload {
        border: 2px dashed #000000;
        display: inline-block;
        padding: 60px;
        cursor: pointer;
        text-align: center;
        background-color: white;
        width: 100%;
        transition: .2s;
    }

    .custom-file-upload.active {
        background: #effbff;
        border: 2px dashed #00bfff;
    }


    .custom-file-upload input {
        width: 100%;
    }


    .custom-file-upload {
        display: flex;
        align-items: center; /* This aligns the child elements (the label) vertically */
        justify-content: center; /* This aligns the child elements (the label) horizontally */
        border: 2px dashed #000;
        text-align: center;
        padding: 20px;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        height: 90%;
        position: relative;

        @include breakpointDown($md) {
            height: 300px;
            margin-bottom: 3rem;
        }
    }


    .custom-file-upload .form-control-file {
        width: 100%;
        height: 100%;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        cursor: pointer;
        transition: .2s;
        z-index: 1;

        &:hover ~ .form-control-label {
            .text-decoration-underline {
                text-decoration: none;
            }
        }
    }


    form {
        height: calc(100% - 50px);
        padding: 0;
        margin-top: 0.5rem;
    }

    .custom-file-upload .form-control-label {
        font-weight: 100;
        cursor: pointer;
        transition: .2s;
        pointer-events: none;
    }

    #fileList {
        gap: 1rem;
        position: absolute;
        top: 1rem;
        right: 1rem;
        left: 1rem;
        display: flex;
        flex-wrap: wrap;
        font-size: 0.9em;
    }

    .filePlusIcon {
        font-size: 7em;
        color: #00bfff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: .2s;
        opacity: 0;
        z-index: 0;
    }


    .form-control-label span {
        color: grey;
        display: block;
        margin: 0.6rem 0;
    }

    .form-control-label .text-decoration-underline {
        color: #000;
        text-decoration: underline;
        margin: 0;

        &:hover {
            text-decoration: none;
        }
    }

}

@include breakpointDown($lg) {
    .text-md-center {
        text-align: center;
    }
}


