html[data-turbo-preview] {
    [data-aos] {
        visibility: hidden;
    }

    .page-navbar .expanded {
        .navbar-menu {
            transform: translateX(100%);
        }

        .navbar-menu-icon {
            .icon-line-01 {
                transform: scaleX(1);
            }

            .icon-line-02 {
                transform: rotate(0);
            }

            .icon-line-03 {
                transform: scaleX(1);
            }
        }
    }
}


.oc-progress-bar {
    height: 3px;
    box-shadow: none;
    background: $primary;
    z-index: 10000;
}


.oc-flash-message {
    padding: rem(20) rem(60.8) rem(20) rem(20);
    max-width: rem(470);
    top: rem(120);
    transform: translateY(-40px);
    box-shadow: $harshShadow;
    border-radius: $buttonRadius;
    color: $dark;
    background: $white !important;
}


@media only screen and (max-width: 768px) {
    .oc-flash-message {
        top: rem(110);
        left: rem(20);
        right: rem(20);
    }
}


.oc-flash-message.flash-show {
    transform: translateY(0);
}


.oc-flash-message a.flash-close {
    top: 50%;
    right: rem(20);
    transform: translateY(-50%);
    filter: brightness(0);
}


.oc-flash-message.success {
    color: $green;
    border-bottom: 2px solid $green;
}


.oc-flash-message.error {
    color: $red;
    border-bottom: 2px solid $red;
}


.oc-attach-loader {
    &:hover:before {
        transform: scale(1);
    }

    &:after {
        width: rem(18);
        height: rem(18);
        margin-left: rem(10);
    }
}
