:root {
    --primary-color: #FEB02B;
    --secondary-color: #EE7217;
    --third-color: #0A0A20;
    --grey-color: #F5F5F5;
    --grey-lighter-color: #FDFCFA;
    --grey-darker-color: #000000;
    --white-color: #F9F9F9;
    --dark-color: #000;
}



// Colors palette
$primary: var(--primary-color);
$secondary: var(--secondary-color);
$third: var(--third-color);

$grey: var(--grey-color);
$grey-lighter: var(--grey-lighter-color);
$grey-darker: var(--grey-darker-color);

$white: var(--white-color);
$darkerWhite: #F0F2F4;

$dark: var(--dark-color);
$lighterDark: #2e3f58;

$lowContrast: #54646F;

$red: #d92f2f;
$yellow: #ecc95d;
$green: #45D669;

// Font
$fontPrimary: 'DM Sans', sans-serif;

// Max container width
$containerSpacing: 20; //in pixels
$maxContainerWidth: 1300px;
$midContainerWidth: 1100px;
$minContainerWidth: 900px;
$sectionOffset: 60; // in pixels
$sectionOffsetMobile: 40; // in pixels

// Main shadow
$mainShadow: 0px 0px 26px rgb(0 0 0 / 5%);
$harshShadow: 0 0 18px rgba(6, 24, 51, 0.25);

// Main border radius
$mainRadius: 12px;
$photoRadius: 20px;
$buttonRadius: 2px;

// Website breakpoints
$breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1199px,
        xxl: 1399px
) !default;

// Simplified breakpoint getters
$xs: map-get($breakpoints, 'xs');
$sm: map-get($breakpoints, 'sm');
$md: map-get($breakpoints, 'md');
$lg: map-get($breakpoints, 'lg');
$xl: map-get($breakpoints, 'xl');
$xxl: map-get($breakpoints, 'xxl');

// Animation speeds
$animationFaster: 120ms ease-in-out;
$animationFast: 200ms ease-in-out;
