* {
    padding: 0;
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

label {
    display: block;
}

input,
button,
select,
textarea {
    margin: 0;
    line-height: inherit;
    font-family: inherit;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    font-family: inherit;

    &:not(:disabled) {
        cursor: pointer;
    }
}

textarea {
    resize: vertical;
}

small {
    font-family: inherit;
}
