:root {
    --h1: clamp(41px, 3.5vw, 55px);
    --h2: clamp(41px, 3.5vw, 55px);
    --h3: clamp(37px, 4vw, 40px);
    --h4: clamp(28px, 2.3vw, 36px);
    --h5: clamp(21.2px, 1.6vw, 22.4px);
    --h6: clamp(19px, 1.5vw, 17px);
}


$h1: var(--h1);
$h2: var(--h2);
$h3: var(--h3);
$h4: var(--h4);
$h5: var(--h5);
$h6: var(--h6);

$text: clamp(16px, 1.3vw, 17px);
$small: clamp(12.80px, 1.3vw, 13.60px);

body {
    font: {
        family: $fontPrimary;
        weight: 600;
        size: $text;
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@mixin heading($size: $h1) {
    font: {
        size: $size;
    }
}


h1, .h1 {
    @include heading($h1);
    line-height: 1.1;
    font-family: 'Titillium Web', sans-serif;
}


h2, .h2 {
    font-family: 'Titillium Web', sans-serif;
    @include heading($h2);
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 1.2rem;
}


h3, .h3 {
    font-family: 'Titillium Web', sans-serif;
    @include heading($h3);
}


h4, .h4 {
    font-family: 'Titillium Web', sans-serif;
    @include heading($h4);
    margin-bottom: 2rem;

    @include breakpointDown($md) {
        text-align: center;
        margin-bottom: 1rem;
    }
}


h5, .h5 {
    font-family: 'Titillium Web', sans-serif;
    @include heading($h5);
    margin-bottom: 1.7rem;
}


h6, .h6 {
    @include heading($h6);
    font-family: 'Titillium Web', sans-serif;
}


p {
    font-weight: 100;
}


a {
    font-weight: 400;
}


p,
a {
    text-decoration: none;
    letter-spacing: 0.2px;
}
