// Applying when width is getting bigger
@mixin breakpointUp($bp: $xs) {
    @media only screen and (min-width: $bp) {
        @content;
    }
}


// Applying when width is getting smaller
@mixin breakpointDown($bp: $xs) {
    @media only screen and (max-width: $bp) {
        @content;
    }
}


@mixin spaceBetweenX($spaceBetween: rem(20)) {
    &:not(:first-child:not(.col-spaces)) {
        margin-left: rem($spaceBetween);
    }
}


@mixin spaceBetweenY($spaceBetween: rem(20)) {
    //&:not(:last-child) {
    //    margin-bottom: rem($spaceBetween);
    //}
}

