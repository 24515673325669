.button {
    padding: 0.9rem 1.7rem;
    position: relative;
    font-weight: 600;
    z-index: 10;
    transition: all $animationFast;
    border-radius: $buttonRadius;
    border: 1px solid $primary;
    letter-spacing: 0;

    &:hover {
        transform: scale(1.02, 1.02);
        text-decoration: none !important;
    }

    &:active {
        transform: scale(0.94, 0.96);
        text-decoration: none !important;
    }

    @include breakpointDown($md) {
        padding: rem(12) rem(24);
    }

    &-primary {
        @extend .button;
        background: $primary;
        color: $dark;
    }

    &-secondary {
        @extend .button;
        border: 1px solid $secondary;
    }

    &-third {
        @extend .button;
        border: 1px solid $dark;
    }

    &-grey {
        @extend .button;
        background: $grey;
        color: $dark;
        border: none;
    }

    &-primary-border {
        @extend .button;
        border: 1px solid $primary;
        background: none;
        color: $primary;
    }

    &-light-border {
        @extend .button;
        border: 1px solid $white;
        background: none;
        color: $white;
    }

    &-no-border {
        @extend .button;
        border: none;
        padding: 0.8rem 2.5rem;

        @include breakpointDown($md) {
            padding: 0.8rem 1.5rem;
        }
    }
}


.active {
    background: $primary;
    color: $dark;
}

