.container-fluid,
.container-sm,
.container {
    padding: {
        right: rem($containerSpacing);
        left: rem($containerSpacing);
    }
}


@include breakpointUp(1300px) {
    .container-sm {
        max-width: $maxContainerWidth;
    }
}


.row {
    > * {
        padding-right: rem(20);
        padding-left: 0;

        &:last-child {
            padding-right: 0;
            padding-left: 0;
        }

        @include breakpointDown($lg) {
            @include spaceBetweenY(20);
            padding-right: 0;
            padding-left: 0;
        }
    }

    &-spaces {
        margin: 0 rem(-10) rem(-20) rem(-10);

        > * {
            @include spaceBetweenY(20);
            padding: 0 rem(10) 0 rem(10);

            &:last-child {
                padding: 0 rem(10) 0 rem(10);
                //margin-bottom: rem(20);
            }
        }
    }

    &-spaces-0 {
        margin: 0;

        > * {
            padding-right: 0;
            padding-left: 0;
        }
    }
}


.section-offset {
    padding-top: rem($sectionOffset);
    padding-bottom: rem($sectionOffset);

    @include breakpointDown(1500px) {
        padding-top: rem($sectionOffsetMobile);
        padding-bottom: rem($sectionOffsetMobile);
    }
}
