* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

label {
  display: block;
}

input,
button,
select,
textarea {
  margin: 0;
  line-height: inherit;
  font-family: inherit;
}

button,
[type=button],
[type=reset],
[type=submit] {
  font-family: inherit;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

textarea {
  resize: vertical;
}

small {
  font-family: inherit;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 1024px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0 0%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
:root {
  --primary-color: #FEB02B;
  --secondary-color: #EE7217;
  --third-color: #0A0A20;
  --grey-color: #F5F5F5;
  --grey-lighter-color: #FDFCFA;
  --grey-darker-color: #000000;
  --white-color: #F9F9F9;
  --dark-color: #000;
}

.container-fluid,
.container-sm, .container-md, .container-lg, .container-xl, .container-xxl,
.container {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

@media only screen and (min-width: 1300px) {
  .container-sm {
    max-width: 1300px;
  }
}
.row > * {
  padding-right: 1.25rem;
  padding-left: 0;
}
.row > *:last-child {
  padding-right: 0;
  padding-left: 0;
}
@media only screen and (max-width: 1024px) {
  .row > * {
    padding-right: 0;
    padding-left: 0;
  }
}
.row-spaces {
  margin: 0 -0.625rem -1.25rem -0.625rem;
}
.row-spaces > * {
  padding: 0 0.625rem 0 0.625rem;
}
.row-spaces > *:last-child {
  padding: 0 0.625rem 0 0.625rem;
}
.row-spaces-0 {
  margin: 0;
}
.row-spaces-0 > * {
  padding-right: 0;
  padding-left: 0;
}

.section-offset {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
@media only screen and (max-width: 1500px) {
  .section-offset {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

input,
textarea,
button,
select {
  outline: none;
  border: none;
  font-size: inherit;
  background: none;
}
input ::placeholder,
textarea ::placeholder,
button ::placeholder,
select ::placeholder {
  color: #54646F;
}

input[disabled],
button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

input[readonly] {
  cursor: not-allowed;
}

ul {
  list-style: none;
}

img,
svg {
  width: 100%;
  display: block;
}

a {
  display: inline-block;
  color: inherit;
  vertical-align: bottom;
}

iframe,
video {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  border: 0;
}

:root {
  --h1: clamp(41px, 3.5vw, 55px);
  --h2: clamp(41px, 3.5vw, 55px);
  --h3: clamp(37px, 4vw, 40px);
  --h4: clamp(28px, 2.3vw, 36px);
  --h5: clamp(21.2px, 1.6vw, 22.4px);
  --h6: clamp(19px, 1.5vw, 17px);
}

body {
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  font-size: clamp(16px, 1.3vw, 17px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, .h1 {
  font-size: var(--h1);
  line-height: 1.1;
  font-family: "Titillium Web", sans-serif;
}

h2, .h2 {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--h2);
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 1.2rem;
}

h3, .h3 {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--h3);
}

h4, .h4 {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--h4);
  margin-bottom: 2rem;
}
@media only screen and (max-width: 768px) {
  h4, .h4 {
    text-align: center;
    margin-bottom: 1rem;
  }
}

h5, .h5 {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--h5);
  margin-bottom: 1.7rem;
}

h6, .h6 {
  font-size: var(--h6);
  font-family: "Titillium Web", sans-serif;
}

p {
  font-weight: 100;
}

a {
  font-weight: 400;
}

p,
a {
  text-decoration: none;
  letter-spacing: 0.2px;
}

.text-primary {
  color: var(--primary-color);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: right;
}
@media only screen and (min-width: 1024px) {
  .text-lg-end {
    text-align: right;
  }
}
.text-justify {
  text-align: justify;
}

.image-ratio {
  max-width: 100%;
  height: 100%;
}

.pb-0 {
  padding-bottom: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .flex-md-row {
    flex-direction: row;
  }
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
@media only screen and (min-width: 1024px) {
  .order-lg-0 {
    order: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .order-lg-1 {
    order: 1;
  }
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

@media only screen and (min-width: 768px) {
  .d-md-block {
    display: block;
  }
}

.mt-3 {
  margin-top: 0.5rem;
}

.mb-5 {
  margin-bottom: 1.5rem;
}

html[data-turbo-preview] [data-aos] {
  visibility: hidden;
}
html[data-turbo-preview] .page-navbar .expanded .navbar-menu {
  transform: translateX(100%);
}
html[data-turbo-preview] .page-navbar .expanded .navbar-menu-icon .icon-line-01 {
  transform: scaleX(1);
}
html[data-turbo-preview] .page-navbar .expanded .navbar-menu-icon .icon-line-02 {
  transform: rotate(0);
}
html[data-turbo-preview] .page-navbar .expanded .navbar-menu-icon .icon-line-03 {
  transform: scaleX(1);
}

.oc-progress-bar {
  height: 3px;
  box-shadow: none;
  background: var(--primary-color);
  z-index: 10000;
}

.oc-flash-message {
  padding: 1.25rem 3.8rem 1.25rem 1.25rem;
  max-width: 29.375rem;
  top: 7.5rem;
  transform: translateY(-40px);
  box-shadow: 0 0 18px rgba(6, 24, 51, 0.25);
  border-radius: 2px;
  color: var(--dark-color);
  background: var(--white-color) !important;
}

@media only screen and (max-width: 768px) {
  .oc-flash-message {
    top: 6.875rem;
    left: 1.25rem;
    right: 1.25rem;
  }
}
.oc-flash-message.flash-show {
  transform: translateY(0);
}

.oc-flash-message a.flash-close {
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
  filter: brightness(0);
}

.oc-flash-message.success {
  color: #45D669;
  border-bottom: 2px solid #45D669;
}

.oc-flash-message.error {
  color: #d92f2f;
  border-bottom: 2px solid #d92f2f;
}

.oc-attach-loader:hover:before {
  transform: scale(1);
}
.oc-attach-loader:after {
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.625rem;
}

.topBar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  background-color: var(--third-color);
  padding: 8px 0;
  font-size: smaller;
  color: rgba(255, 255, 255, 0.9);
}
.topBar .contact-info {
  font-weight: 100;
  display: flex;
  align-items: center;
}
.topBar .contact-info a {
  padding-left: 0.3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}
@media only screen and (max-width: 1024px) {
  .topBar .contact-info a {
    padding-left: 0.1rem;
    padding-right: 1rem;
  }
}
.topBar .contact-info a:last-child {
  padding-left: 3rem;
}
@media only screen and (max-width: 1024px) {
  .topBar .contact-info a:last-child {
    padding-left: 0.1rem;
  }
}
.topBar .contact-info a svg {
  height: 1rem;
  width: auto;
}
.topBar .social-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}
@media only screen and (max-width: 365px) {
  .topBar .social-icons {
    display: none;
  }
}
.topBar .social-icons a svg {
  height: 1.2rem;
  width: auto;
}

header {
  background: var(--third-color);
  color: var(--white-color);
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 99999;
}
header nav {
  display: flex;
}
@media only screen and (max-width: 1024px) {
  header nav {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
header nav .navbar-brand {
  padding: 1.2rem;
  margin-right: 1rem;
  padding-left: 0;
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-brand {
    padding: 0.8rem 0;
  }
}
header nav .navbar-brand img {
  max-width: 11rem;
}
header nav .navbar-collapse {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
@media only screen and (min-width: 1025px) {
  header nav .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-collapse {
    flex-direction: column;
    width: 100%;
  }
}
header nav .navbar-collapse ul {
  margin-right: auto;
  display: flex;
  align-items: center;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-collapse ul {
    flex-direction: column;
    width: 100%;
  }
}
header nav .navbar-collapse ul .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
}
header nav .navbar-collapse ul .nav-item:hover {
  background: #2F2F41;
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-collapse ul .nav-item:hover {
    background: none;
  }
}
header nav .navbar-collapse ul .nav-item-active:hover {
  background: none;
}
header nav .navbar-collapse ul .nav-item .nav-link {
  margin-left: 14px;
  margin-right: 14px;
  color: white;
  font-size: medium;
  height: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-collapse ul .nav-item .nav-link {
    padding: 1rem 0;
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 0.7rem;
  }
}
header nav .navbar-collapse ul .nav-item .nav-link-active {
  color: var(--primary-color);
}
header nav .navbar-collapse ul .nav-item .nav-link-active svg {
  fill: var(--primary-color) !important;
}
header nav .navbar-collapse ul .nav-item .dropdown-toggle {
  cursor: pointer;
}
header nav .navbar-collapse ul .nav-item .dropdown-toggle svg {
  display: none;
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-collapse ul .nav-item .dropdown-toggle svg {
    display: block;
    fill: #eee;
    width: 0.7rem;
    margin-left: 0.5rem;
  }
}
header nav .navbar-collapse ul .nav-item .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  width: max-content;
  max-width: 227.5px;
  padding: 0.5rem 0;
  flex-direction: column;
  font-size: 1rem;
  color: #fff;
  background-color: #2F2F41;
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-collapse ul .nav-item .dropdown-menu {
    position: initial;
    max-width: none;
    width: 100%;
    text-align: center;
    padding-top: 0;
    background: none;
  }
}
header nav .navbar-collapse ul .nav-item .dropdown-menu .dropdown-item {
  padding: 0.8rem 1.5rem;
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-collapse ul .nav-item .dropdown-menu .dropdown-item {
    padding: 0.7rem 0;
  }
}
header nav .navbar-collapse ul .nav-item .dropdown-menu .dropdown-item:hover {
  background: rgba(255, 255, 255, 0.1490196078);
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-collapse ul .nav-item .dropdown-menu .dropdown-item:hover {
    background: none;
  }
}
header nav .navbar-collapse ul .nav-item .dropdown-menu.show {
  display: flex;
}
header nav .navbar-collapse ul .dropdown {
  position: relative;
}
@media only screen and (min-width: 1025px) {
  header nav .navbar-collapse ul .dropdown:hover .dropdown-menu {
    display: flex;
  }
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-collapse ul .dropdown {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 1024px) {
  header nav .navbar-collapse > a {
    margin: 1rem 0 2rem;
  }
}
header nav .collapse:not(.show) {
  display: none;
}
header nav .navbar-menu-icon {
  padding: 1.25rem;
  margin-right: -1.25rem;
  z-index: 10;
  display: block;
}
@media only screen and (min-width: 1025px) {
  header nav .navbar-menu-icon {
    display: none;
  }
}
header nav .navbar-menu-icon .icon-line-item {
  margin: 0.25rem 0;
  width: 1.25rem;
  height: 0.125rem;
  border-radius: 2px;
  background: white;
  transition: transform 200ms ease-in-out;
  display: block;
}
header nav .navbar-menu-icon .icon-line-01 {
  transform-origin: left;
}
header nav .navbar-menu-icon .icon-line-03 {
  transform-origin: right;
}

header nav .navbar-menu-icon:not(.collapsed) .icon-line-01 {
  transform: translate(3px, -1px) rotate(45deg) scaleX(0.5);
}
header nav .navbar-menu-icon:not(.collapsed) .icon-line-02 {
  transform: rotate(-45deg);
}
header nav .navbar-menu-icon:not(.collapsed) .icon-line-03 {
  transform: translate(-3px, 1px) rotate(45deg) scaleX(0.5);
}

@media only screen and (max-width: 1024px) {
  footer .row > .col-lg-6:first-child {
    order: 1;
  }
}
footer .footer-col {
  color: #fff;
}
@media only screen and (max-width: 576px) {
  footer .footer-col {
    text-align: center;
  }
}
footer .footer-col img {
  max-width: 13rem;
}
@media only screen and (max-width: 576px) {
  footer .footer-col img {
    margin: 0 auto;
  }
}
footer .footer-col .footer-social {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1.3rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}
@media only screen and (max-width: 576px) {
  footer .footer-col .footer-social {
    justify-content: center;
  }
}
footer .footer-col .footer-social a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
footer .footer-col .footer-social a svg {
  height: 1rem;
  width: auto;
}
footer .footer-col h5 {
  margin-top: 2.5rem;
}
footer .footer-col ul li a {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 100;
  gap: 0.6rem;
  line-height: 1.5;
}
@media only screen and (max-width: 576px) {
  footer .footer-col ul li a {
    justify-content: center;
  }
}
footer .footer-col ul li a svg {
  height: 1.1rem;
  width: auto;
}
footer .footer-col ul li .nav-link-active {
  color: var(--primary-color);
}
footer .footer-col ul li:last-child {
  margin-bottom: 0;
}
footer .footer-col-right {
  padding: 5rem;
  background: var(--grey-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0;
}
@media only screen and (max-width: 1199px) {
  footer .footer-col-right {
    padding: 3rem;
    padding-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  footer .footer-col-right {
    text-align: center;
  }
}
@media only screen and (max-width: 576px) {
  footer .footer-col-right {
    padding: 3rem 2rem;
  }
}
footer .footer-col-right .promo-title {
  color: #EE7217;
}
footer .footer-col-right h4 {
  margin-bottom: 1.4rem;
}
footer .footer-col-right p {
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 2.5rem;
  font-size: 0.9em;
}
@media only screen and (max-width: 576px) {
  footer .footer-col-right p {
    margin-bottom: 2rem;
  }
}
footer .footer-col-right .button-container {
  font-size: smaller;
  display: flex;
  gap: 0.5rem;
}
@media only screen and (max-width: 768px) {
  footer .footer-col-right .button-container {
    justify-content: center;
    align-items: center;
    font-size: medium;
  }
}
@media only screen and (max-width: 576px) {
  footer .footer-col-right .button-container {
    flex-direction: column;
  }
}
footer .total-footer {
  text-align: center;
  background: var(--dark-color);
  color: #c5c5c5;
  padding: 1rem 0;
  margin-bottom: 0;
}
footer .total-footer .total-footer-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 576px) {
  footer .total-footer .total-footer-box {
    flex-direction: column;
    gap: 0.6rem;
  }
}
footer .total-footer .total-footer-box h6 {
  font-size: 0.8em;
  margin-bottom: 0;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}
footer .total-footer .total-footer-box h6 a {
  color: var(--primary-color);
  text-decoration: underline;
}
footer .total-footer .total-footer-box h6 a:hover {
  text-decoration: none;
}

.button, .button-no-border, .button-light-border, .button-primary-border, .button-grey, .button-third, .button-secondary, .button-primary {
  padding: 0.9rem 1.7rem;
  position: relative;
  font-weight: 600;
  z-index: 10;
  transition: all 200ms ease-in-out;
  border-radius: 2px;
  border: 1px solid var(--primary-color);
  letter-spacing: 0;
}
.button:hover, .button-no-border:hover, .button-light-border:hover, .button-primary-border:hover, .button-grey:hover, .button-third:hover, .button-secondary:hover, .button-primary:hover {
  transform: scale(1.02, 1.02);
  text-decoration: none !important;
}
.button:active, .button-no-border:active, .button-light-border:active, .button-primary-border:active, .button-grey:active, .button-third:active, .button-secondary:active, .button-primary:active {
  transform: scale(0.94, 0.96);
  text-decoration: none !important;
}
@media only screen and (max-width: 768px) {
  .button, .button-no-border, .button-light-border, .button-primary-border, .button-grey, .button-third, .button-secondary, .button-primary {
    padding: 0.75rem 1.5rem;
  }
}
.button-primary {
  background: var(--primary-color);
  color: var(--dark-color);
}
.button-secondary {
  border: 1px solid var(--secondary-color);
}
.button-third {
  border: 1px solid var(--dark-color);
}
.button-grey {
  background: var(--grey-color);
  color: var(--dark-color);
  border: none;
}
.button-primary-border {
  border: 1px solid var(--primary-color);
  background: none;
  color: var(--primary-color);
}
.button-light-border {
  border: 1px solid var(--white-color);
  background: none;
  color: var(--white-color);
}
.button-no-border {
  border: none;
  padding: 0.8rem 2.5rem;
}
@media only screen and (max-width: 768px) {
  .button-no-border {
    padding: 0.8rem 1.5rem;
  }
}

.active {
  background: var(--primary-color);
  color: var(--dark-color);
}

section {
  margin: 7rem 0;
}
@media only screen and (max-width: 1024px) {
  section {
    margin: 5rem 0;
  }
}
@media only screen and (max-width: 768px) {
  section {
    margin: 4rem 0;
  }
}
@media only screen and (max-width: 576px) {
  section {
    margin: 3rem 0;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid var(--third-color);
  background: #fff;
  transform: rotate(270deg);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 9999;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
  transition: 0.2s all;
  cursor: pointer;
}
.scroll-to-top svg {
  width: 16px;
  height: 16px;
  fill: var(--third-color);
}
.scroll-to-top:hover {
  background-color: #f7f7ff;
}
.scroll-to-top.fade-in {
  animation: fade-in 0.3s forwards;
}
.scroll-to-top.fade-out {
  animation: fade-out 0.3s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
h1, h2 {
  color: black;
}

h1 {
  margin-bottom: 0.5rem;
  width: 100%;
}

h2 {
  width: fit-content;
}

.main-slider {
  margin-top: 3rem;
}
@media only screen and (max-width: 1024px) {
  .main-slider {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .main-slider {
    margin-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .slider-content {
    padding: 6rem 70px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 576px) {
  .slider-content {
    padding: 5rem 30px;
  }
}
.slider-content p {
  margin: 1.2rem 0 0;
  margin-bottom: 3rem;
}

.swiper-pagination {
  position: absolute;
  left: 1.25rem !important;
  text-align: left !important;
  bottom: 2rem !important;
}
@media only screen and (max-width: 768px) {
  .swiper-pagination {
    bottom: 18rem !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    text-align: center !important;
  }
}

.swiper-pagination-bullet {
  width: 13px !important;
  height: 13px !important;
  background-color: #fff !important;
  border: 1px solid;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  background: var(--primary-color) !important;
}

.swiper-slide-hidden {
  opacity: 0;
}

.slider-image {
  background-size: cover !important;
  width: 100% !important;
  height: 100% !important;
  background-position: center;
}
.slider-image img {
  height: 30rem;
  object-fit: cover;
  object-position: center center;
}
@media only screen and (max-width: 576px) {
  .slider-image img {
    height: 25rem;
  }
}

.swiper-slide-visible {
  opacity: 0 !important;
}

.swiper-slide-active {
  opacity: 1 !important;
}

.swiper-container {
  height: 100%;
  overflow: hidden;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .swiper-container:before {
    width: 56% !important;
  }
}

.swiper-slide {
  display: flex !important;
  align-items: center !important;
  padding: 0 1.25rem;
  height: initial !important;
}
@media only screen and (max-width: 768px) {
  .swiper-slide {
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
}
.swiper-slide .coponukameleft {
  background: #F5F5F5;
  width: 640px;
}
@media only screen and (max-width: 768px) {
  .swiper-slide .coponukameleft {
    height: 450px;
  }
}
@media only screen and (max-width: 1399px) {
  .swiper-slide .coponukameleft {
    min-width: auto;
  }
}
@media only screen and (max-width: 1399px) {
  .swiper-slide {
    max-width: 100%;
  }
  .swiper-slide .coponukameleft {
    width: 50%;
  }
  .swiper-slide .coponukameright {
    width: 50%;
  }
  .swiper-slide .coponukameright img {
    width: 65% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .swiper-slide .coponukameleft {
    width: 70%;
  }
}
@media only screen and (max-width: 768px) {
  .swiper-slide .coponukameleft {
    width: 100%;
  }
  .swiper-slide .coponukameright {
    width: 100%;
  }
  .swiper-slide .coponukameright img {
    width: 100% !important;
  }
}

.swiper-wrapper {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box !important;
}

.coponukame {
  padding: 3rem;
  width: 100%;
  margin: 0;
}

.referencie {
  text-align: right;
}

.coponukameleft {
  padding: 74px;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  align-items: flex-start;
}
@media only screen and (max-width: 1199px) {
  .coponukameleft {
    padding: 3rem;
    padding-bottom: 4rem;
    padding-left: 0;
  }
}
@media only screen and (max-width: 768px) {
  .coponukameleft {
    text-align: center;
    background: #F5F5F5;
    align-items: center;
    padding-left: 3rem;
  }
}
@media only screen and (max-width: 576px) {
  .coponukameleft {
    padding: 3rem 2rem;
  }
}
.coponukameleft a {
  display: inline-block;
}
.coponukameleft .buttons-group {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 0.5rem;
}
@media only screen and (max-width: 576px) {
  .coponukameleft .buttons-group {
    justify-content: center;
    margin-bottom: 4rem;
  }
}
.coponukameleft p {
  margin-top: 1rem;
}

.coponukameright {
  padding: 74px;
  padding-right: 0;
  color: var(--grey-color);
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  gap: 0.5rem;
}
@media only screen and (max-width: 1199px) {
  .coponukameright {
    padding: 3rem;
    justify-content: center;
    padding-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .coponukameright {
    background: #0A0A20;
    padding-right: 3rem;
  }
}
@media only screen and (max-width: 576px) {
  .coponukameright {
    padding: 3rem 2rem;
    text-align: center;
    flex-direction: column;
  }
}
.coponukameright p {
  line-height: 1.5;
  letter-spacing: 0;
}

.colored-section {
  position: relative;
  margin: 0 1.5vw;
}
@media only screen and (max-width: 768px) {
  .colored-section {
    margin: 0;
  }
}
.colored-section:before {
  content: "";
  position: absolute;
  width: 50%;
  left: 0;
  top: 0;
  height: 100%;
  background: #F5F5F5;
  z-index: -1;
}
@media only screen and (max-width: 768px) {
  .colored-section:before {
    display: none;
  }
}
.colored-section:after {
  content: "";
  position: absolute;
  width: 50%;
  left: 50%;
  top: 0;
  height: 100%;
  background: #0A0A20;
  z-index: -1;
}
@media only screen and (max-width: 768px) {
  .colored-section:after {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .colored-section .container-lg {
    padding: 0;
  }
}

.colored-section-reverse {
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .colored-section-reverse .container-lg {
    padding: 0;
    min-width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .colored-section-reverse .coponukameleft {
    background: #0A0A20;
    padding: 3rem 1.25rem;
    text-align: left;
  }
  .colored-section-reverse .coponukameleft .footer-col {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .colored-section-reverse .footer-col-right {
    padding: 3rem 1.25rem;
  }
}
.colored-section-reverse:before {
  background: #0A0A20;
}
@media only screen and (max-width: 1024px) {
  .colored-section-reverse:before {
    display: none;
  }
}
.colored-section-reverse:after {
  background: #F5F5F5;
}
@media only screen and (max-width: 1024px) {
  .colored-section-reverse:after {
    display: none;
  }
}

.colored-section-top {
  overflow-x: hidden;
  margin-top: 1.5vw;
}
.colored-section-top:after {
  background: none;
}
@media only screen and (max-width: 768px) {
  .colored-section-top {
    margin-top: 0;
  }
}
.colored-section-top .coponukameleft {
  justify-content: center;
}
.colored-section-top .coponukameright {
  padding: 0;
  height: 22rem;
}
@media only screen and (max-width: 1024px) {
  .colored-section-top .coponukameright {
    height: 17rem;
  }
}
.colored-section-top .coponukameright img,
.colored-section-top .coponukameright iframe {
  position: absolute;
  height: 100%;
  object-position: center;
  width: 50%;
  top: 0;
}
@media only screen and (max-width: 768px) {
  .colored-section-top .coponukameright img,
  .colored-section-top .coponukameright iframe {
    width: 100%;
    position: initial;
  }
}

.padding-section {
  padding: 0 3rem;
}
@media only screen and (max-width: 1024px) {
  .padding-section {
    padding: 0 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .padding-section {
    padding: 0;
  }
}

button {
  color: black;
}

.headlines-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}
@media only screen and (max-width: 1199px) {
  .headlines-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
  }
}
@media only screen and (max-width: 576px) {
  .headlines-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    gap: 1rem;
  }
}

.headlines {
  font-weight: 500;
  display: block;
  color: #1a1a1a;
  flex: 1;
}
@media only screen and (max-width: 1024px) {
  .headlines {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }
}
@media only screen and (max-width: 768px) {
  .headlines {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    margin: 1rem auto;
  }
}
.headlines svg {
  height: 1.6rem;
  width: auto;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 1024px) {
  .headlines svg {
    margin-bottom: 0;
    height: auto;
    flex: none;
  }
}

.footer-container {
  max-width: unset !important;
}

@media (max-width: 992px) {
  .paddingswiper {
    padding: 2rem;
    padding-top: 0px;
  }
  .coponukame {
    padding: 2rem;
  }
}
/* Add a media query for mobile devices (adjust the max-width as needed) */
@media (max-width: 768px) {
  .headlines {
    text-align: center;
  }
  .paddingswiper {
    padding: 0rem;
    padding-top: 0px;
  }
  .coponukame {
    padding: 0rem;
  }
  .referencie {
    text-align: unset;
  }
}
/* Base styling */
.img-overlay-container {
  position: relative;
}

.img-fluid-custom {
  z-index: 1;
  max-width: 43%;
}
@media only screen and (max-width: 1199px) {
  .img-fluid-custom {
    max-width: 48%;
  }
}
@media only screen and (max-width: 1024px) {
  .img-fluid-custom {
    max-width: 70%;
    max-height: 32rem;
    object-fit: cover;
  }
}
@media only screen and (max-width: 576px) {
  .img-fluid-custom {
    max-height: 32rem;
    max-width: 100%;
    height: 60rem;
  }
}

.text-overlay {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translateY(-50%);
  padding: 5rem 6rem;
  background-color: #F5F5F5;
  border: 10px solid #fff;
  border-radius: 0;
}
@media only screen and (max-width: 1399px) {
  .text-overlay {
    padding: 3rem 4.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .text-overlay {
    left: 1rem;
    right: 1rem;
    padding: 1rem;
  }
}
.text-overlay h4 {
  position: relative;
}
@media only screen and (max-width: 768px) {
  .text-overlay h4 {
    text-align: left;
  }
}
.text-overlay h4:before {
  content: "";
  width: 3px;
  height: 50%;
  position: absolute;
  top: 54%;
  left: -1.7rem;
  transform: translateY(-50%);
  background: var(--secondary-color);
}
@media only screen and (max-width: 768px) {
  .text-overlay h4:before {
    left: -0.5rem;
  }
}
.text-overlay p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.card-text-custom {
  padding-left: 17px;
  font-size: 15px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .card-text-custom {
    padding-left: 17px;
    font-size: 13px;
  }
}
:root {
  --no-of-slides: 5;
  --slides-in-view: 5;
  --slide-width: 230px;
  --slide-height: 110px;
  --iteration-time: 15s;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(var(--slide-width) * var(--no-of-slides) * -1));
  }
}
.partnersslider .carousel {
  max-width: 850px;
  margin: 0 auto;
}

.partnersslider .carousel__wrapper {
  display: flex;
  align-items: center;
  width: calc(var(--slides-in-view) * var(--slide-width));
  overflow: hidden;
  margin: 0 auto;
}

.partnersslider .carousel__slide {
  animation: scroll var(--iteration-time) linear infinite;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: var(--slide-width);
  height: var(--slide-height);
  box-sizing: border-box;
}

.partnersslider .carousel__image {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  height: 100%;
  margin: 36px 50px;
  background-position-y: center !important;
}
@media only screen and (max-width: 768px) {
  .partnersslider .carousel__image {
    margin: 16px 30px;
  }
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 500;
}

.button-offer {
  background: #FEB02B;
  border: 0;
  padding: 8px 20px;
  margin-right: 9px;
  font-weight: 700;
}

.button-call {
  border: 2px solid #0A0A20;
  padding: 8px 20px;
  margin-right: 9px;
  font-weight: 700;
  border-radius: 2px;
}

@media (max-width: 768px) {
  .button-offer {
    margin-top: 5px;
  }
  .top-container {
    display: none;
  }
  .button-call {
    margin-top: 5px;
  }
  .footer-row {
    flex-direction: column-reverse;
    /* Reverses the order on mobile */
  }
  .location {
    justify-content: center;
    /* Centered in mobile */
    text-align: center;
  }
}
.collapsebutton {
  background: #F5F5F5;
  border: 0;
  padding: 18px 22px;
  text-align: left;
  font-weight: 600;
  width: 100%;
  max-width: 85%;
  margin: 10px;
  margin-left: 0;
  font-family: "Titillium Web", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collapsebutton svg {
  width: 1rem;
}
.collapsebutton svg path {
  fill: var(--secondary-color);
}

.text-box p {
  margin-bottom: 2rem;
  letter-spacing: 0;
  line-height: 1.5;
}
.text-box p:last-child {
  margin-bottom: 0;
}
.text-box img {
  max-width: 95%;
  margin-right: auto;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 768px) {
  .text-box img {
    max-width: 100%;
    margin-bottom: 2.5rem;
    height: auto;
  }
}
.text-box .rever img {
  margin-left: auto;
}
.text-box h6 {
  margin-bottom: 1rem;
}
.text-box ul li {
  font-weight: 100;
  position: relative;
  margin-left: 0.5rem;
  margin-bottom: 0.9rem;
  line-height: 1.5;
}
.text-box ul li:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  background-color: var(--primary-color);
  width: 1.5px;
  left: -0.5rem;
}

.vyrobcoviaSection h4 {
  margin-bottom: 0;
}
.vyrobcoviaSection img {
  max-width: 170px;
  width: 90%;
  margin: 0 1rem;
}
@media only screen and (max-width: 768px) {
  .vyrobcoviaSection img {
    max-width: 210px;
    margin: 1rem auto;
  }
}

.activedcollapse button {
  background: #0A0A1F;
  color: white;
}

.activedcollapse button i {
  color: #EE7217;
}

.collapseinside {
  display: none;
}

.collapseright {
  background-color: var(--grey-color);
  padding: 0;
  align-self: center;
}
.collapseright .collapseright-item-box {
  padding: 3rem;
}
@media only screen and (max-width: 1199px) {
  .collapseright .collapseright-item-box {
    padding: 1.5rem;
  }
}
.collapseright .collapseright-item-box p {
  font-size: 0.9em;
  line-height: 1.5;
  margin-bottom: 2rem;
}
.collapseright .collapseright-item-box a {
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 0.9em;
}

@media only screen and (max-width: 768px) {
  .collapseright-item {
    background-color: var(--grey-color);
    padding: 2rem;
  }
  .collapseright-item p {
    font-size: 0.9em;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  .collapseright-item a {
    color: var(--secondary-color);
    font-weight: bold;
    font-size: 0.9em;
  }
}
@media only screen and (max-width: 576px) {
  .collapseright-item {
    padding: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .activedcollapse .collapseinside {
    display: flex;
    align-items: stretch;
  }
  .activedcollapse .collapseinside img {
    object-fit: cover;
    width: 45%;
  }
}
@media only screen and (max-width: 576px) {
  .activedcollapse .collapseinside {
    display: flex;
    flex-direction: column;
  }
  .activedcollapse .collapseinside img {
    width: 100%;
  }
}

.collapseright span {
  font-size: 15px;
  color: #EE7217;
  font-weight: 500;
}

collapse span {
  font-size: 15px;
  color: #EE7217;
  font-weight: 500;
}

.collapsemain {
  padding: 40px;
}

.bggrey {
  background-color: #F5F5F5;
}

.font14 {
  font-size: 14px;
}

@media (max-width: 768px) {
  .collapseright {
    display: none;
  }
  .collapsemain {
    padding: 0px;
  }
  .collapsebutton {
    max-width: 100%;
  }
  .collapsemain h4 {
    text-align: center;
  }
  .activedcollapse button {
    margin-bottom: 0px;
  }
}
.vsetkyrefencie {
  font-size: 0.9rem;
  color: var(--secondary-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}
@media only screen and (max-width: 576px) {
  .vsetkyrefencie {
    justify-content: center;
    margin-top: -0.6rem;
    margin-bottom: 1.5rem;
  }
}
.vsetkyrefencie svg {
  width: 1rem;
  margin-left: 0.5rem;
}
.vsetkyrefencie svg path {
  fill: var(--secondary-color);
}

.referencia {
  background: var(--grey-color);
}
.referencia .referencia-text {
  padding: 1.5rem 2rem;
}
.referencia .referencia-text p {
  margin-bottom: 0.4rem;
  font-size: 0.9em;
}

.referencia-images {
  padding: 1.2rem 2rem;
  margin-top: 0.4rem;
  margin-bottom: 1.2rem;
  display: none;
}
.referencia-images-0 {
  display: block;
}
.referencia-images p {
  font-weight: 500;
  font-size: 0.9em;
}

.referenciegrid p {
  font-size: 14px;
}

.button-slider {
  overflow-x: auto;
  display: flex;
  white-space: nowrap;
  gap: 0.6rem;
  padding: 0.2rem 0;
  margin: -1rem 0;
}

.slideheigth {
  max-height: 350px;
}

.imgshowroom {
  max-width: 230px;
  margin: 10px;
  margin-right: 20px;
}

.vyrobcoviaright {
  text-align: right;
}

@media (max-width: 768px) {
  .vyrobcoviaright {
    text-align: center;
  }
  .contactgrid {
    padding: 20px;
  }
}
@media (max-width: 768px) {
  .rever {
    flex-direction: column-reverse;
    /* Reverses the order on mobile */
  }
}
.card {
  background-color: var(--grey-color);
  padding: 0.1rem 1.5rem 1.5rem;
  position: relative;
  height: 100%;
  z-index: 1;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 1024px) {
  .card {
    height: calc(100% - 1.5rem);
  }
}
@media only screen and (max-width: 576px) {
  .card {
    padding: 0.1rem 2.5rem 2rem;
  }
}
.card h6 {
  font-size: 5em;
  margin-bottom: 1rem;
  font-weight: bold;
}
.card p {
  font-weight: bold;
  line-height: 1.5;
}
.card span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -0.6rem;
  font-size: 19em;
  line-height: 1;
  color: #EAEAEA;
  z-index: -1;
}
@media only screen and (max-width: 1024px) {
  .card span {
    font-size: 15em;
  }
}
@media only screen and (max-width: 576px) {
  .card span {
    font-size: 16em;
  }
}

.coponukameleft h4 {
  font-weight: 700;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 768px) {
  .coponukameleft h4 {
    margin-bottom: 1rem;
  }
}

.postupdivbody {
  background-color: #F5F5F5;
  background-size: contain;
  background-position: center right;
  background-position: calc(100% - 0px) center;
  background-repeat: no-repeat;
}

.postupdivbody h5 {
  font-size: 61px;
  font-weight: 700;
}

orange {
  color: #EE7217;
}

.pl10 {
  padding-left: 10px;
}

.contact-section h6 {
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}
.contact-section p {
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
}
.contact-section .footer-social-icon {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  margin-top: 4rem;
  font-weight: 600;
}
.contact-section .footer-social-icon svg {
  width: 1.1rem;
}

form {
  background: var(--grey-color);
  padding: 3rem;
}
@media only screen and (max-width: 1024px) {
  form {
    padding: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  form {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  form {
    padding: 1.5rem;
  }
}

.form-group label {
  font-weight: lighter;
  display: block !important;
}
.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  background: #fff;
  padding: 0.9rem 1rem;
  margin: 0.5rem 0 1.5rem;
}

.text-red-validation {
  color: red;
  font-weight: lighter;
  margin-top: -1rem;
  display: block;
  margin-bottom: 1rem;
  font-size: 0.9em;
}

.form-group-gdpr {
  display: flex;
  margin-bottom: 2rem;
}
.form-group-gdpr input {
  margin-right: 0.5rem;
  width: 1rem;
}
.form-group-gdpr input:checked {
  background-color: var(--primary-color);
}
.form-group-gdpr label {
  font-weight: 400;
}

@media only screen and (max-width: 1024px) {
  .showroomSection img {
    margin-top: 1rem;
  }
}

.cenovaponuka-section {
  margin-top: 3rem;
  /* Base style for labels associated with checkboxes */
  /* Style for when the associated checkbox is not checked */
  /* Style for when the associated checkbox is checked */
  /* Style for the label when the associated checkbox is checked */
  /* Style for when the associated checkbox is not checked */
  /* Style for when the associated checkbox is checked */
  /* Style for the label when the associated checkbox is checked */
  /* Optional: Add a class to style the check icon */
}
@media only screen and (max-width: 1024px) {
  .cenovaponuka-section h1 {
    margin: 0 auto 1.5rem;
  }
}
.cenovaponuka-section .row {
  margin: 20px !important;
}
.cenovaponuka-section svg {
  display: none;
  width: 1.1rem;
}
.cenovaponuka-section p {
  font-size: 1.1em;
}
.cenovaponuka-section h5 {
  margin-top: 1.5rem;
}
.cenovaponuka-section .steps-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.cenovaponuka-section .step {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  font-family: "Titillium Web", sans-serif;
}
@media only screen and (max-width: 576px) {
  .cenovaponuka-section .step {
    flex-direction: column;
    gap: 0.2rem;
  }
  .cenovaponuka-section .step .step-title {
    font-size: 0.9em;
  }
}
.cenovaponuka-section .step-number {
  background-color: white;
  color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.1;
}
.cenovaponuka-section .step-active .step-number {
  background-color: #f0ad4e;
  border: 1px solid #f0ad4e;
  color: black;
}
.cenovaponuka-section .step.current .step-number {
  border: 1px solid #f0ad4e;
  color: black;
}
@media (max-width: 768px) {
  .cenovaponuka-section .steps-bar {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.cenovaponuka-section .pad5 {
  padding: 5px;
}
.cenovaponuka-section .kalkcard .title {
  padding: 21px;
  padding-bottom: 0px;
}
.cenovaponuka-section .kalkcard {
  padding: 21px;
  background-color: #fff;
  font-size: 15px;
  min-height: 214px;
  position: relative;
  border: 1px solid #fff;
  cursor: pointer;
}
.cenovaponuka-section .kalcard-active {
  border: 1px solid var(--primary-color);
}
.cenovaponuka-section .kalkcard:hover {
  border: 1px solid var(--primary-color);
}
.cenovaponuka-section .kalkcardmulti {
  padding: 21px;
  background-color: #fff;
  font-size: 15px;
  min-height: 214px;
  position: relative;
}
.cenovaponuka-section .kalcardMultiItem {
  margin: 12px -21px 0 -21px;
  padding: 5px 21px;
  cursor: pointer;
  border: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cenovaponuka-section .kalcardMultiItem-active {
  border: 1px solid var(--primary-color);
}
.cenovaponuka-section .kalcardMultiItem:hover {
  border: 1px solid var(--primary-color);
}
.cenovaponuka-section .checkbox-label {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s, border-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cenovaponuka-section .checkbox-labelinline {
  width: 23px;
  height: 23px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s, border-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cenovaponuka-section .checkbox-input {
  opacity: 0;
  float: right;
}
.cenovaponuka-section .checkbox-input:not(:checked) + .checkbox-label > .fa-check {
  display: none;
}
.cenovaponuka-section .checkbox-input:checked + .checkbox-label > .fa-check {
  display: inline;
  color: #000000;
  /* Set your desired color */
}
.cenovaponuka-section .checkbox-input:checked + .checkbox-label {
  border-color: var(--secondary-color);
}
.cenovaponuka-section .checkbox-input:not(:checked) + .checkbox-labelinline > .fa-check {
  display: none;
}
.cenovaponuka-section .checkbox-input:checked + .checkbox-labelinline > .fa-check {
  display: inline;
  color: #000000;
  /* Set your desired color */
}
.cenovaponuka-section .checkbox-input:checked + .checkbox-labelinline {
  border-color: var(--secondary-color);
}
.cenovaponuka-section .fa-check {
  font-size: 16px;
  /* Adjust the size as needed */
}
.cenovaponuka-section .dalejbutton {
  float: right;
}
.cenovaponuka-section .spatbutton {
  float: left;
}
@media only screen and (max-width: 576px) {
  .cenovaponuka-section .cenova-buttons {
    display: flex;
    gap: 0.5rem;
  }
}
.cenovaponuka-section .upload-header-text {
  font-size: 1em;
  font-weight: 400;
}
@media only screen and (max-width: 768px) {
  .cenovaponuka-section .upload-header-text {
    margin-top: 1rem;
  }
}
.cenovaponuka-section .custom-file-upload {
  border: 2px dashed #000000;
  display: inline-block;
  padding: 60px;
  cursor: pointer;
  text-align: center;
  background-color: white;
  width: 100%;
  transition: 0.2s;
}
.cenovaponuka-section .custom-file-upload.active {
  background: #effbff;
  border: 2px dashed #00bfff;
}
.cenovaponuka-section .custom-file-upload input {
  width: 100%;
}
.cenovaponuka-section .custom-file-upload {
  display: flex;
  align-items: center; /* This aligns the child elements (the label) vertically */
  justify-content: center; /* This aligns the child elements (the label) horizontally */
  border: 2px dashed #000;
  text-align: center;
  padding: 20px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  height: 90%;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .cenovaponuka-section .custom-file-upload {
    height: 300px;
    margin-bottom: 3rem;
  }
}
.cenovaponuka-section .custom-file-upload .form-control-file {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  transition: 0.2s;
  z-index: 1;
}
.cenovaponuka-section .custom-file-upload .form-control-file:hover ~ .form-control-label .text-decoration-underline {
  text-decoration: none;
}
.cenovaponuka-section form {
  height: calc(100% - 50px);
  padding: 0;
  margin-top: 0.5rem;
}
.cenovaponuka-section .custom-file-upload .form-control-label {
  font-weight: 100;
  cursor: pointer;
  transition: 0.2s;
  pointer-events: none;
}
.cenovaponuka-section #fileList {
  gap: 1rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  left: 1rem;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
}
.cenovaponuka-section .filePlusIcon {
  font-size: 7em;
  color: #00bfff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s;
  opacity: 0;
  z-index: 0;
}
.cenovaponuka-section .form-control-label span {
  color: grey;
  display: block;
  margin: 0.6rem 0;
}
.cenovaponuka-section .form-control-label .text-decoration-underline {
  color: #000;
  text-decoration: underline;
  margin: 0;
}
.cenovaponuka-section .form-control-label .text-decoration-underline:hover {
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .text-md-center {
    text-align: center;
  }
}