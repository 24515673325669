input,
textarea,
button,
select {
    outline: none;
    border: none;
    font-size: inherit;
    background: none;

    ::placeholder {
        color: $lowContrast;
    }
}

input[disabled],
button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

input[readonly] {
    cursor: not-allowed;
}

ul {
    list-style: none;
}

img,
svg {
    width: 100%;
    display: block;
}

a {
    display: inline-block;
    color: inherit;
    vertical-align: bottom;
}

iframe,
video {
    width: 100%;
    height: 100%;
    vertical-align: bottom;
    border: 0;
}
