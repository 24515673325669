footer {

    .row > .col-lg-6:first-child {
        @include breakpointDown($lg) {
            order: 1;
        }
    }

    .footer-col {
        color: #fff;

        @include breakpointDown($sm) {
            text-align: center;
        }

        img {
            max-width: 13rem;

            @include breakpointDown($sm) {
                margin: 0 auto;
            }
        }

        .footer-social {
            display: flex;
            align-items: center;
            gap: 2rem;
            margin-top: 1.3rem;
            font-weight: 400;
            color: rgb(255 255 255 / 90%);

            @include breakpointDown($sm) {
                justify-content: center;
            }

            a {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                svg {
                    height: 1rem;
                    width: auto;
                }
            }
        }

        h5 {
            margin-top: 2.5rem;
        }

        ul {
            li {
                a {
                    margin-bottom: 1.5rem;
                    display: flex;
                    align-items: center;
                    color: rgb(255 255 255 / 90%);
                    font-weight: 100;
                    gap: 0.6rem;
                    line-height: 1.5;

                    @include breakpointDown($sm) {
                        justify-content: center;
                    }

                    svg {
                        height: 1.1rem;
                        width: auto;
                    }
                }

                .nav-link-active {
                    color: $primary;
                }

                &:last-child {
                    margin-bottom: 0;
                }

            }
        }
    }

    .footer-col-right {
        padding: 5rem;
        background: $grey;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 0;

        @include breakpointDown($xl) {
            padding: 3rem;
            padding-right: 0;
        }

        @include breakpointDown($md) {
            text-align: center;
        }

        @include breakpointDown($sm) {
            padding: 3rem 2rem;
        }


        .promo-title {
            color: #EE7217;
        }


        h4 {
            margin-bottom: 1.4rem;
        }


        p {
            letter-spacing: 0;
            line-height: 1.5;
            margin-bottom: 2.5rem;
            font-size: 0.9em;

            @include breakpointDown($sm) {
                margin-bottom: 2rem;
            }
        }

        .button-container {
            font-size: smaller;
            display: flex;
            gap: 0.5rem;

            @include breakpointDown($md) {
                justify-content: center;
                align-items: center;
                font-size: medium;
            }

            @include breakpointDown($sm) {
                flex-direction: column;
            }
        }
    }

    .total-footer {
        text-align: center;
        background: $dark;
        color: #c5c5c5;
        padding: 1rem 0;
        margin-bottom: 0;


        .total-footer-box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include breakpointDown($sm) {
                flex-direction: column;
                gap: 0.6rem;
            }

            h6 {
                font-size: 0.8em;
                margin-bottom: 0;
                font-family: $fontPrimary;
                font-weight: 400;

                a {
                    color: $primary;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }


    }
}
